<template lang="pug">
  tr(:class="{ 'invalid-section': !isInvalidNext && item.$error }")
    td.table-item
      span {{ item.sources_car_option.option_type.$model }}
    td.table-item
      span {{ item.sources_car_option.name.$model }}
    td.table-item.dropdown-cell
      .item-row
        .type
          AppDropdown.cy-selectability-type(
            batch-select
            allow-empty
            close-on-select
            order-direction="keep"
            :placeholder="$t('actions.select')"
            :value="selectabilityType"
            :items="selectabilityTypes"
            :class="{ invalid: item.selectability_type.$error, 'fixed-size': !isFeeTypeVisible }"
            @select="setSelectabilityType"
          )
        .type
          AppDropdown.cy-fee-type-name(
            v-if="isFeeTypeVisible"
            batch-select
            allow-empty
            close-on-select
            order-direction="keep"
            :placeholder="$t('actions.select')"
            :value="feeType"
            :items="feeTypes"
            :class="{ invalid: item.fee_type.$error }"
            @select="setFeeType"
          )
        .prices-container-wrapper
          .prices-container
            PeriodSpecifiedFee(
              v-if="item.selectability_type.$model !== no && item.fee_type.$model === periodSpecifiedFee"
              :item="item"
              @add-item="removeItem"
              @remove-item="addItem"
            )
            FeeOptions(
              v-if="item.selectability_type.$model !== no && feeOptions.includes(item.fee_type.$model)"
              :item="item"
            )
            MaxFee(
              v-if="item.selectability_type.$model !== no && maxOptions.includes(item.fee_type.$model)"
              :item="item"
            )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"
  import { find, isEmpty } from "lodash-es"
  import { FEE_OPTIONS, MAX_OPTIONS, FIXED_FEE, PERIOD_SPECIFIED_FEE, NO } from "@/config/constants"
  import { getFeeTypes, getSelectabilityTypes } from "@/config/translations_helper"

  export default {
    props: {
      item: Object,
      isInvalidNext: Boolean
    },

    data() {
      return {
        no: NO,
        feeOptions: FEE_OPTIONS,
        maxOptions: MAX_OPTIONS,
        fixedFee: FIXED_FEE,
        periodSpecifiedFee: PERIOD_SPECIFIED_FEE
      }
    },

    mixins: [withPermissions],

    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      FeeOptions: () => import("./FeeOptions"),
      MaxFee: () => import("./MaxFee"),
      PeriodSpecifiedFee: () => import("./PeriodSpecifiedFee")
    },

    computed: {
      selectabilityTypes() {
        return getSelectabilityTypes(this.$i18n)
      },

      feeTypes() {
        return getFeeTypes(this.$i18n)
      },

      isFeeTypeVisible() {
        return this.item.selectability_type.$model !== NO
      },

      feeType() {
        return find(this.feeTypes, { id: this.item.fee_type.$model })
      },

      selectabilityType() {
        return find(this.selectabilityTypes, { id: this.item.selectability_type.$model })
      }
    },

    watch: {
      feeType(type) {
        if (type?.id === PERIOD_SPECIFIED_FEE) {
          if (isEmpty(this.item.fees.$model)) {
            this.item.fees.$model = [{ day: null, fee: 0 }]
            this.item.fees.$reset()
          }
        } else {
          this.item.fees.$model = []
        }
      },

      selectabilityType({ id }) {
        if (id === this.no) {
          this.item.fees.$model = []
        }
      }
    },

    methods: {
      setSelectabilityType({ id }) {
        this.resetValidations()
        this.item.selectability_type.$model = id
        if (id === this.no) this.item.fee_type.$model = null
      },

      setFeeType({ id }) {
        this.resetValidations()
        this.item.fee_type.$model = id
      },

      addItem() {
        this.item.fees.$model.push({ day: null, fee: 0 })
      },

      removeItem(index) {
        this.item.fees.$model.splice(index, 1)
      },

      resetValidations() {
        this.item.fee_type.$reset()
        this.item.fee.$reset()
        this.item.fees.$reset()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .table-item
    .item-row
      align-items: flex-start
      display: flex

      .type
        width: 30%

        ::v-deep
          .app-select
            width: 100%

      .prices-container
        padding-inline: 8px
        width: 290px

        &-wrapper
          align-items: flex-start
          align-self: center
          display: flex
          padding-block: 2px

  input,
  .fixed-size
    width: 120px

  .before-input
    width: 85px

  .icon
    color: $default-purple
    cursor: pointer
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

  @media (min-width: 1240px) and (max-width: 1420px)
    .table-item
      .item-row
        .type
          width: 20%

        .prices-container-wrapper
          width: 60%

  @media screen and (max-width: 1240px)
    .table-item
      .item-row
        flex-direction: column

        .type,
        .prices-container-wrapper
          width: 100%
</style>
